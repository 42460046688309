.table {
  &.is-borderless {
    td, th {
      border: none!important;
    }
  }

  &.is-selectable {
    tr {
      cursor: pointer!important;
    }
  }

  tr {
    &.has-text-vcentered {
      td, th {
        vertical-align: middle!important;
      }
    }

    &.is-active {
      background-color: #48c78e!important;
      color: white!important;

      td, th {
        border-color: #42b782!important;
      }
    }
  }

  &.has-text-centered {
    td, th {
      text-align: center!important;

      &.has-text-left {
        text-align: left!important;
      }
    }
  }
}

.tile.notification {
  cursor: pointer!important;
}

.media-content {
  .checkbox {
    pointer-events: none!important;
  }
}

.navbar-item img {
  max-height: 2.75rem!important;
  margin: 0 1rem;
}